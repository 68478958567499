/**
 * Main Navigation
 */
/* import EngagementRings from '@content/menu/engagement-rings';
import WeddingBands from '@content/menu/wedding-bands';
import Diamonds from '@content/menu/diamonds';
import Gemstones from '@content/menu/gemstones';
import Jewellery from '@content/menu/jewellery';
import Gold from '@content/menu/gold';
import AboutUs from '@content/menu/aboutus';
*/

import dynamic from 'next/dynamic';

const EngagementRings = dynamic(() => import('@content/menu/engagement-rings'));
const WeddingBands = dynamic(() => import('@content/menu/wedding-bands'));
const Diamonds = dynamic(() => import('@content/menu/diamonds'));
const Gemstones = dynamic(() => import('@content/menu/gemstones'));
const Jewellery = dynamic(() => import('@content/menu/jewellery'));
const Gold = dynamic(() => import('@content/menu/gold'));
const AboutUs = dynamic(() => import('@content/menu/aboutus'));

/**
 * Main Menu
 */
export const navigation = {
  categories: [
    {
      id: 'engagement-rings',
      name: 'Engagement Rings',
      component: EngagementRings,
      pages: [
        'setting',
        'solitaire',
        'pave',
        'channel-set',
        'side-stone',
        'vintage',
        'halo',
        '3-stone',
        'dual-pave',
        'man-engagement-ring',
        'curve',
        'twisted',
        'hidden-diamond',
        'hidden-halo',
        'vow-of-love-engagement-rings',
      ],
    },

    {
      id: 'wedding-bands',
      name: 'Wedding Rings',
      component: WeddingBands,
      pages: [
        'women',
        'women/classic',
        'women/dainty',
        'women/diamond',
        'women/eternity',
        'women/alternative',
        'women/half-eternity',
        'women/full-eternity',
        'women/plain',
        'men',
        'men/classic',
        'men/diamond',
        'men/alternative',
        'men/plain'
      ],
    },

    {
      id: 'loose-diamonds',
      name: 'Diamonds',
      component: Diamonds,
      pages: [
        'round',
        'princess',
        'emerald',
        'asscher',
        'marquise',
        'cushion',
        'radiant',
        'pear',
        'oval',
        'heart',
        'shop-all',
        'pure-hearts',
        'earth-natural-diamonds',
        'labgrown-diamonds',
        'labgrown-diamonds/pure-hearts',
        'fancy-colour-diamonds/shop-all',
        'fancy-colour-diamonds/earth-natural-diamonds',
        'fancy-colour-diamonds/labgrown-diamonds',
      ],
    },

    {
      id: 'gemstones',
      name: 'Gemstones',
      component: Gemstones,
      pages: [
        'sapphire',
        'ruby',
        'emerald',
        'aquamarine',
        'amethyst',
        'citrine',
        'morganite',
        'tanzanite',
        'tourmaline',
        'topaz',
        'peridot',
        'shop-all',
      ],
    },

    {
      id: 'jewellery',
      name: 'Jewellery',
      component: Jewellery,
      pages: [
        'jewellery/rings/gemstone',
        'jewellery/rings/diamond',
        'jewellery/rings/eternity',
        'jewellery/rings/pearls',
        'jewellery/rings/gold',
        'jewellery/rings/dainty',
        'jewellery/rings/color-diamond',
        'jewellery/rings/plain',
      ]
    },
    {
      id: 'golds',
      name: 'Gold',
      component: Gold,
    },
    {
      id: 'about-us',
      name: 'About us',
      component: AboutUs,
    },
  ],
  pages: [
    { name: 'my account', href: '/my-account' },
    { name: 'my orders', href: '/my-account/orders' },
    { name: 'my wishlist', href: '/my-account/wishlist' },
    { name: 'address', href: '/my-account/address' },
    { name: 'change password', href: '/my-account/change-password' },
  ],
};
