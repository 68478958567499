import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/@next+third-parties@14.2.27_next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_r_c378abd6ec8ebccc1d52a07ee9a8ac57/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/@next+third-parties@14.2.27_next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_r_c378abd6ec8ebccc1d52a07ee9a8ac57/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/@next+third-parties@14.2.27_next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_r_c378abd6ec8ebccc1d52a07ee9a8ac57/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.72.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Harrington.ttf\",\"display\":\"swap\",\"variable\":\"--font-harrington\",\"preload\":true}],\"variableName\":\"HarringtonFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/LaurenScriptRegular.ttf\",\"display\":\"swap\",\"variable\":\"--font-lauren-script\",\"weight\":\"200\",\"preload\":true}],\"variableName\":\"LaurenScriptRegularFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Tunga.woff\",\"display\":\"swap\",\"variable\":\"--font-tunga\",\"preload\":true}],\"variableName\":\"TungaFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/MonotypeCorsiva.ttf\",\"display\":\"swap\",\"variable\":\"--font-monotype-corsiva\",\"preload\":true}],\"variableName\":\"MonotypeCorsivaFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Cardo\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"greek\",\"greek-ext\",\"latin\",\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-cardo\",\"preload\":true}],\"variableName\":\"CardoFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Outfit\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-outfit\",\"preload\":true}],\"variableName\":\"OutfitFont\"}");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/react-toastify@10.0.6_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/rc-slider@11.1.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/rc-slider/assets/index.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/react-datepicker@7.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datepicker/dist/react-datepicker.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/src/app/[lang]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/app/provider/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/app/provider/toaster-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/src/assets/css/custom-plugins.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/src/assets/css/rc-drawer.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/src/assets/css/scrollbar.css");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/src/assets/css/swiper-carousel.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/common/countdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/common/drawer/managed-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/common/modal/managed-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KlaviyoPopup"] */ "/home/jewel-store/src/components/seo/klaviyo-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/ui/scroll-restoration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ManagedUIContext"] */ "/home/jewel-store/src/contexts/ui.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterScript"] */ "/home/jewel-store/src/layouts/footer/footer-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/layouts/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderScript"] */ "/home/jewel-store/src/layouts/header/header-script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/layouts/modern/header.tsx");
